<template>
  <v-theme-provider :theme="theme">
    <v-snackbar
      v-bind="finalSnackbarProps"
      v-model="snackbar"
      close-on-content-click
    >
      <template v-if="contentComponent">
        <component :is="contentComponent" />
      </template>
      <template v-else>
        {{ text }}
      </template>
      <template
        v-if="showCloseButton"
        #actions
      >
        <v-btn
          color="default"
          v-bind="closeButtonProps"
          variant="text"
          @click="snackbar = false"
        >
          {{ closeButtonText }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-theme-provider>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  text: {
    type: String,
    required: false,
    default: '',
  },
  contentComponent: {
    required: false,
  },
  snackbarProps: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  showCloseButton: {
    type: Boolean,
    required: false,
    default: true,
  },
  closeButtonProps: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  closeButtonText: {
    type: String,
    required: false,
    default: 'Close',
  },
  theme: {
    type: String,
    required: false,
  },
  destroy: {
    type: Function,
    required: true,
  },
});

const snackbar = ref(true);

const finalSnackbarProps = computed(() => {
  return {
    ...props.snackbarProps,
    onAfterLeave() {
      props.snackbarProps.onAfterLeave?.();
      props.destroy();
    },
  };
});
</script>
