export default {
  '$vuetify.input.appendAction': '???',
  '$vuetify.open': '打开',
  '$vuetify.close': '关闭',
  hello: '你好',
  appTitle: 'Vuetify 3 模板',
  labelUserName: '用户名',
  labelPassword: '密码',
  placeHolderUserName: '请输入用户名',
  placeHolderPassword: '请输入密码',
  hintPassword: '密码包含大小写英文字母，数字，符号',
  labelRememberMe: '保持登录状态',
  labelLogin: '登录',
  errorUserNameNotEmpty: '未输入用户名',
  errorPasswordNotEmpty: '未输入密码',
  'label-home': '主页',
  'label-helloworld': '你好世界',
  'label-common': '共通部品',
  'label-dev': '开发参考',
  'label-dialog': '对话框',
  'label-snackbar': '消息条',
  'label-toast': '提示框',
  'label-crud': 'CRUD示例',
  'label-icon': '图标',
  'title-logout': '确认要退出吗？',
  'user-create': '新建',
  'user-edit': '编辑',
  'new-item-btn': '新建',
  'user-avatar': '头像',
  'user-name': '名字',
  'user-sex': '性别',
  'user-age': '年龄',
  'user-actions': '操作',
  'user-delete-tip-message': '是否确实要删除此项目？',
  'user-ok': '确认',
  'user-cancle': '取消',
  'user-save': '保存',
  'tooltip-text': '未成年',
  '$vuetify.input.clear': '清除',
  'user-address': '地址',
  'user-postalcode': '邮编',
  'table-no-data-text': '没有数据',
  '$vuetify.pagination.ariaLabel.root': '分页',
  '$vuetify.pagination.ariaLabel.previous': '上一页',
  '$vuetify.pagination.ariaLabel.next': '下一页',
  '$vuetify.pagination.ariaLabel.currentPage': '当前页码',
  '$vuetify.pagination.ariaLabel.page': '页',
};
