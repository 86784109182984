import { delay } from './config';

function ok200(res, ctx) {
  return res(
    delay(),
    ctx.status(200),
    ctx.json({
      code: 1,
      message: 'ok',
    })
  );
}

function error500(res, ctx, error) {
  return res(
    delay(),
    ctx.status(500),
    ctx.json({
      code: 0,
      message: error,
    })
  );
}

function errorSessionTimeout(res, ctx) {
  return res(
    delay(),
    ctx.status(401),
    ctx.json({
      code: -401,
      message: 'session timeout',
    })
  );
}

export { ok200, error500, errorSessionTimeout };
