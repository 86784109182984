import { sessionApi } from '@/apis';
import { isLogin } from '@/store/auth';

function sendHeartBeat() {
  if (isLogin()) sessionApi.session();
}

function start() {
  setInterval(
    sendHeartBeat,
    import.meta.env.VITE_APP_HEARTBEAT_INTERVAL_MINISECONDS ?? 30000
  );
}

export default { start };
