export default {
  '$vuetify.input.appendAction': '???',
  '$vuetify.open': 'Open',
  '$vuetify.close': 'Close',
  hello: 'Hello',
  appTitle: 'Vuetify 3 Template',
  labelUserName: 'User Name',
  labelPassword: 'Password',
  placeHolderUserName: 'Please Enter Your User Name',
  placeHolderPassword: 'Please Enter Your Password',
  hintPassword:
    'The password should contain uppercase and lowercase letters, numbers, and symbols',
  labelRememberMe: 'Remember Me',
  labelLogin: 'login',
  errorUserNameNotEmpty: 'username should not be empty',
  errorPasswordNotEmpty: 'password should not be empty',
  'label-home': 'Home Page',
  'label-helloworld': 'Hello World',
  'label-common': 'Components',
  'label-dev': 'Development',
  'label-dialog': 'Dialogs',
  'label-snackbar': 'Snackbar',
  'label-toast': 'Toast',
  'label-crud': 'CRUD Sample',
  'label-icon': 'Icon',
  'title-logout': 'Are you sure to logout?',
  'user-create': 'New Item',
  'user-edit': 'Edit Item',
  'new-item-btn': 'New Item',
  'user-avatar': 'Avatar',
  'user-name': 'Name',
  'user-sex': 'Sex',
  'user-age': 'Age',
  'user-actions': 'Actions',
  'user-delete-tip-message': 'Are you sure you want to delete this item?',
  'user-ok': 'Ok',
  'user-cancle': 'Cancel',
  'user-save': 'Save',
  'tooltip-text': 'Under Age',
  '$vuetify.input.clear': 'clear',
  'user-address': 'Address',
  'user-postalcode': 'postalCode',
  'table-no-data-text': 'no data',
  '$vuetify.pagination.ariaLabel.root': 'Pagination',
  '$vuetify.pagination.ariaLabel.previous': 'prev',
  '$vuetify.pagination.ariaLabel.next': 'next',
  '$vuetify.pagination.ariaLabel.currentPage': 'Current Page',
  '$vuetify.pagination.ariaLabel.page': 'page',
};
