import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { routeApi } from '@/apis';
import router from '@/router';
import { getComponentByPath } from '@/router/router';

export const useRouteStore = defineStore(
  'route',
  () => {
    const route = ref(null);

    const routeValue = computed(() => {
      return route.value;
    });

    function fetchRoute() {
      return new Promise((resolve, reject) => {
        routeApi
          .route()
          .then(res => {
            setRoute(res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    function generateDynamicRoute(value) {
      if (value && value.length > 0) {
        value.map(item => {
          if (getComponentByPath(item.path).length > 0) {
            router.addRoute('', {
              name: item.name,
              component: getComponentByPath(item.path)[0].parent,
              children: [
                {
                  path: item.path,
                  name: item.name,
                  component: getComponentByPath(item.path)[0].component,
                },
              ],
            });
          }

          generateDynamicRoute(item.subs);
        });
      }
    }

    function setRoute(value) {
      route.value = value;
      generateDynamicRoute(value);
    }

    return {
      route,
      routeValue,
      setRoute,
      fetchRoute,
      generateDynamicRoute,
    };
  },
  {
    persist: true,
  }
);
