import KDemo from '@/components/koi/KDemo';
import KAppBtn from '@/components/koi/KAppBtn';
import KAppBtnMenu from '@/components/koi/KAppBtnMenu';
import KAppSheet from '@/components/koi/KAppSheet';

const plugin = {
  install(app, globalOptions) {
    app
      .component('k-demo', KDemo)
      .component('k-app-btn', KAppBtn)
      .component('k-app-btn-menu', KAppBtnMenu)
      .component('k-app-sheet', KAppSheet);
    console.log(globalOptions);
  },
};

export { plugin as default };
