import { createRouter, createWebHistory } from 'vue-router';
import { constantRouter } from './router';
import { useAuthStore, isLogin } from '@/store/auth';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRouter,
});

router.beforeEach(async to => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !isLogin()) {
    auth.returnUrl = to.fullPath;
    return '/login';
  }
});

export default router;
