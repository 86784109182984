/**
 * dynamic route
 */
export const asyncRouter = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/Home.vue'),
    children: [],
  },
];

/**
 * basic route
 */
export const constantRouter = [
  {
    name: 'home',
    path: '/',
    component: () => import('@/layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/HelloWorld.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('../views/login/Login.vue'),
      },
    ],
  },
];

const components = [
  {
    path: '/home',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/home/Home.vue'),
  },
  {
    path: '/helloworld',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/HelloWorld.vue'),
  },
  {
    path: '/common/dialogs',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/components/Dialogs.vue'),
  },
  {
    path: '/common/snackbar',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/components/Snackbar.vue'),
  },
  {
    path: '/common/toast',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/components/Toast.vue'),
  },
  {
    path: '/crud',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/crud/Crud.vue'),
  },
  {
    path: '/dev/icon',
    parent: () => import('@/layouts/AdminLayout.vue'),
    component: () => import('@/views/components/Icon.vue'),
  },
];

export function getComponentByPath(path) {
  return components.filter(item => {
    return item.path === path;
  });
}
