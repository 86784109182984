import { defineStore } from 'pinia';
import { loginApi, logoutApi } from '@/apis';
import router from '../router';
import { useSettingsStore } from './settings';
import { useTokenStore } from './token';
import { useRouteStore } from './route';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: isLogin() ?? JSON.parse(isLogin()),
    returnUrl: null,
  }),
  actions: {
    login(body) {
      return new Promise((resolve, reject) => {
        loginApi
          .login(body)
          .then(authRes => {
            const { fetchRoute } = useRouteStore();
            fetchRoute()
              .then(() => {
                this.user = authRes;
                const { rememberMeValue } = useSettingsStore();
                const { setToken } = useTokenStore();

                const storage = rememberMeValue ? localStorage : sessionStorage;
                storage.setItem('user', JSON.stringify(authRes.data.user));
                setToken(authRes.data.accessToken);
                resolve('ok');

                router.push(this.returnUrl || '/home');
              })
              .catch(error => reject(error));
          })
          .catch(error => reject(error));
      });
    },
    logout() {
      const { setToken } = useTokenStore();
      setToken(null);
      this.user = null;
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      logoutApi.logout();
      router.push('/login');
    },
  },
});

export function isLogin() {
  const { rememberMeValue } = useSettingsStore();
  const storage = rememberMeValue ? localStorage : sessionStorage;
  return storage.getItem('user');
}
