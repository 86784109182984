import app from '@/main';

function getInstance() {
  let instance = null;
  try {
    instance = app._context.config.globalProperties;
  } catch (error) {
    console.error(error);
  }
  return instance;
}

function openConfirm(options) {
  const instance = getInstance();
  return instance?.$confirm(options);
}

function openSnackbar(options) {
  const instance = getInstance();
  return instance?.$snackbar(options);
}

function infoToast(text) {
  const instance = getInstance();
  return instance?.$infoToast(text);
}

function successToast(text) {
  const instance = getInstance();
  return instance?.$successToast(text);
}

function warningToast(text) {
  const instance = getInstance();
  return instance?.$warningToast(text);
}

function errorToast(text) {
  const instance = getInstance();
  return instance?.$errorToast(text);
}

export {
  getInstance,
  openConfirm,
  openSnackbar,
  infoToast,
  successToast,
  warningToast,
  errorToast,
};
