import { useMockStore } from './store';
import { errorSessionTimeout } from './response';

export function checkAuth(req) {
  const headers = req.headers;
  const unsecureToken = headers.get('authorization')?.replace('Bearer ', '');
  const secureToken = req.cookies.accessToken;
  const token = secureToken ?? unsecureToken;
  const { sessions } = useMockStore();
  const session = sessions.map(item => {
    return item.sessionId == token;
  });
  if (session?.length > 0) {
    return {
      authenticated: true,
    };
  } else {
    return {
      authenticated: false,
      message: 'session timeout',
    };
  }
}

export function filterHandler(auth, res, ctx) {
  const { message } = auth;
  if (message == 'session timeout') {
    return errorSessionTimeout(res, ctx);
  }
}
