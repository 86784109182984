// Utilities
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useHealthStore = defineStore(
  'health',
  () => {
    const time = ref();

    const timeValue = computed(() => time.value);

    function setTime(value) {
      time.value = value;
    }

    return {
      time,
      timeValue,
      setTime,
    };
  },
  {
    persist: true,
  }
);
