// Utilities
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useSettingsStore = defineStore(
  'settings',
  () => {
    // const theme = ref(
    //   JSON.parse(localStorage.getItem("settings"))?.theme ?? "light"
    // );
    const theme = ref('light');
    const locale = ref('en');
    const rememberMe = ref(false);
    const fish = ref(false);
    const drawerOpen = ref(false);
    const drawerPin = ref(false);

    const themeValue = computed(() => theme.value);
    const localeValue = computed(() => locale.value);
    const rememberMeValue = computed(() => rememberMe.value);
    const fishValue = computed(() => fish.value);
    const drawerOpenValue = computed(() => drawerOpen.value);
    const drawerPinValue = computed(() => drawerPin.value);

    function setTheme(value) {
      theme.value = value;
    }
    function setLocale(value) {
      locale.value = value;
    }
    function setRememberMe(value) {
      rememberMe.value = value;
    }
    function setFish(value) {
      fish.value = value;
    }
    function setDrawerOpen(value) {
      drawerOpen.value = value;
    }
    function setDrawerPin(value) {
      drawerPin.value = value;
    }

    return {
      theme,
      locale,
      rememberMe,
      fish,
      drawerOpen,
      drawerPin,
      themeValue,
      localeValue,
      rememberMeValue,
      fishValue,
      drawerOpenValue,
      drawerPinValue,
      setTheme,
      setLocale,
      setRememberMe,
      setFish,
      setDrawerOpen,
      setDrawerPin,
    };
  },
  {
    persist: [
      {
        storage: localStorage,
        paths: ['theme', 'locale', 'fish', 'drawerPin'],
      },
      {
        storage: sessionStorage,
        paths: ['rememberMe'],
      },
    ],
  }
);
