import { rest } from 'msw';
import { delay } from './config';
import { nanoid } from 'nanoid';
import { SESSION_TIMEOUT_SHORT } from './session';
import { useMockStore } from './store';

export const login = [
  rest.post('/api/login', (req, res, ctx) => {
    const LONG_TIMEOUT_MINISECONDS = SESSION_TIMEOUT_SHORT * 100 * 60 * 1000;
    const SHORT_TIMEOUT_MINISECONDS = SESSION_TIMEOUT_SHORT * 60 * 1000;
    const longExpiryDate = new Date(Date.now() + LONG_TIMEOUT_MINISECONDS);
    const shortExpiryDate = new Date(Date.now() + SHORT_TIMEOUT_MINISECONDS);

    const sessionId = nanoid();

    const { sessionsValue, setSessions } = useMockStore();

    let rememberMe = false;
    const body = req.json();
    rememberMe = body.rememberMe ?? false;

    sessionsValue.push({
      sessionId: sessionId,
      // expires: rememberMe ? longExpiryDate : shortExpiryDate,
    });

    setTimeout(
      () => {
        setSessions(
          sessionsValue.filter(item => {
            console.log(sessionId + ' is timeout, remove it from session');
            return item.sessionId != sessionId;
          })
        );
      },
      rememberMe ? LONG_TIMEOUT_MINISECONDS : SHORT_TIMEOUT_MINISECONDS
    );

    return res(
      delay(),
      ctx.status(200),
      ctx.cookie('accessToken', sessionId, {
        expires: rememberMe ? longExpiryDate : shortExpiryDate,
        httpOnly: true,
      }),
      ctx.json({
        code: 1,
        message: 'ok',
        accessToken: sessionId,
        user: {
          name: 'hello',
        },
      })
    );
  }),
];
