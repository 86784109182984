/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

import App from './App.vue';
import { createApp } from 'vue';
import { registerPlugins } from '@/plugins';
import { setupWorker } from 'msw';
import { handlers } from './mocks';
import heartbeat from './utils/heartbeat';
import '@/styles/main.scss';
import '@/styles/tailwind.scss';

const app = createApp(App);

registerPlugins(app);

if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'production'
) {
  const worker = setupWorker(...handlers);
  worker.start({ onUnhandledRequest: 'bypass' });
}

app.mount('#app');

heartbeat.start();

export default app;
