import { rest } from 'msw';
import { delay } from '../config';

export const readUser = [
  rest.get('/api/user/read', (req, res, ctx) => {
    const queryParams = req.url.searchParams;
    const name = queryParams.get('name');
    const sex = queryParams.get('sex');
    const user_data = [
      {
        id: 1,
        avatar: '',
        name: 'user1',
        age: 22,
        sex: 0,
        address: 'NNNNNNNNNNNNNN',
        postalCode: '123456',
      },
      {
        id: 2,
        avatar: '',
        name: 'user2',
        age: 16,
        sex: 0,
        address: 'MMMMMMMMMMMMMMMM',
        postalCode: '555555',
      },
      {
        id: 3,
        avatar: '',
        name: 'user3',
        age: 19,
        sex: 0,
        address: 'LLLLLLLLLLLLLLLL',
        postalCode: '855345',
      },
      {
        id: 4,
        avatar: '',
        name: 'user4',
        age: 23,
        sex: 1,
        address: 'KKKKKKKKKKKKKKKKKKK',
        postalCode: '656342',
      },
      {
        id: 5,
        avatar: '',
        name: 'user5',
        age: 20,
        sex: 0,
        address: 'KKKKKKKKKKKKKKKKKKK',
        postalCode: '086435',
      },
      {
        id: 6,
        avatar: '',
        name: 'user6',
        age: 25,
        sex: 0,
        address: 'JJJJJJJJJJJJJJJJJ',
        postalCode: '096432',
      },
      {
        id: 7,
        avatar: '',
        name: 'user7',
        age: 13,
        sex: 1,
        address: 'IIIIIIIIIIIIIIIIIII',
        postalCode: '852943',
      },
      {
        id: 8,
        avatar: '',
        name: 'user8',
        age: 18,
        sex: 0,
        address: 'HHHHHHHHHHHHHHH',
        postalCode: '777777',
      },
      {
        id: 9,
        avatar: '',
        name: 'user9',
        age: 23,
        sex: 0,
        address: 'GGGGGGGGGGGGGGG',
        postalCode: '99999',
      },
      {
        id: 10,
        avatar: '',
        name: 'user10',
        age: 26,
        sex: 1,
        address: 'FFFFFFFFFFFFFFFF',
        postalCode: '888888',
      },
      {
        id: 11,
        avatar: '',
        name: 'user11',
        age: 17,
        sex: 0,
        address: 'EEEEEEEEEEEEEE',
        postalCode: '652344',
      },
      {
        id: 12,
        avatar: '',
        name: 'user12',
        age: 22,
        sex: 1,
        address: 'DDDDDDDDDDDDD',
        postalCode: '973333',
      },
      {
        id: 13,
        avatar: '',
        name: 'user13',
        age: 21,
        sex: 0,
        address: 'CCCCCCCCCCCCCCCCC',
        postalCode: '222222',
      },
      {
        id: 14,
        avatar: '',
        name: 'user14',
        age: 14,
        sex: 0,
        address: 'BBBBBBBBBBBB',
        postalCode: '256412',
      },
      {
        id: 15,
        avatar: '',
        name: 'user15',
        age: 22,
        sex: 1,
        address: 'AAAAAAAAAAA',
        postalCode: '654823',
      },
    ];
    let filter_name_data = [];
    let filter_sex_data = [];
    user_data.map(item => {
      if (item.name.indexOf(name) != -1) {
        filter_name_data.push(item);
      }
    });
    if (sex != '' && sex != null) {
      filter_name_data.map(item => {
        if (item.sex == sex) {
          filter_sex_data.push(item);
        }
      });
    } else {
      filter_sex_data = filter_name_data;
    }

    return res(delay(), ctx.status(200), ctx.json(filter_sex_data));
  }),
];
