import { rest } from 'msw';
import { delay } from './config';
import { checkAuth, filterHandler } from './guard';

export const dummy = [
  rest.get('/api/dummy', (req, res, ctx) => {
    const auth = checkAuth(req);
    if (!auth.authenticated) {
      return filterHandler(auth, res, ctx);
    }
    return res(delay(), ctx.status(200), ctx.json([{ id: 1, name: 'John' }]));
  }),
];
