import { rest } from 'msw';
import { checkAuth, filterHandler } from './guard';
import { ok200 } from './response';
import { useMockStore } from './store';

export const logout = [
  rest.get('/api/logout', (req, res, ctx) => {
    const auth = checkAuth(req);
    if (!auth.authenticated) {
      return filterHandler(auth, res, ctx);
    }

    const { setSessions } = useMockStore();
    setSessions([]);

    return ok200(res, ctx);
  }),
];
