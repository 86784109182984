import { rest } from 'msw';
import { ok200, errorSessionTimeout } from './response';
import { useMockStore } from './store';

export const SESSION_TIMEOUT_SHORT = import.meta.env
  .VITE_APP_MOCK_SESSION_TIMEOUT_MINUTES;

export const session = [
  rest.get('/api/session', (req, res, ctx) => {
    const { sessionsValue } = useMockStore();
    const headers = req.headers;
    const unsecureToken = headers.get('authorization')?.replace('Bearer ', '');
    const secureToken = req.cookies.accessToken;
    const token = secureToken ?? unsecureToken;
    const session = sessionsValue.filter(item => {
      return item.sessionId == token;
    });
    if (session?.length > 0) {
      return ok200(res, ctx);
    } else {
      return errorSessionTimeout(res, ctx);
    }
  }),
];
