/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify, { i18n } from './vuetify';
import pinia from '../store';
import router from '../router';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import shortcuts from './shortcuts';
import koi from './koi';
import { useRouteStore } from '@/store/route';

pinia.use(piniaPluginPersistedstate);

export function registerPlugins(app) {
  app.use(vuetify).use(pinia).use(shortcuts).use(i18n).use(koi);

  const { routeValue, generateDynamicRoute } = useRouteStore();
  generateDynamicRoute(routeValue);
  app.use(router);
}
