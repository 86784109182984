export default {
  '$vuetify.input.appendAction': '???',
  '$vuetify.open': '開く',
  '$vuetify.close': '閉じる',
  hello: 'こんにちは',
  appTitle: 'Vuetify 3 テンプレート',
  labelUserName: 'ユーザ名',
  labelPassword: 'パスワード',
  placeHolderUserName: 'ユーザ名をご入力してください',
  placeHolderPassword: 'パスワードをご入力してください',
  hintPassword: '大文字と小文字のアルファベット、数字、記号',
  labelRememberMe: '登録状態保持',
  labelLogin: 'ログイン',
  errorUserNameNotEmpty: '未記入',
  errorPasswordNotEmpty: '未記入',
  'label-home': 'ホームページ',
  'label-helloworld': 'こんにちは世界よ',
  'label-common': '共通部品',
  'label-dev': '開発参考',
  'label-dialog': 'ポップアップ',
  'label-snackbar': 'スナックバー',
  'label-toast': 'トースト',
  'label-crud': 'CRUDサンプル',

  'label-icon': 'アイコン',
  'title-logout': 'ログアウトしてよろしいでしょうか？',
  'user-create': '新規',
  'user-edit': '編集',
  'new-item-btn': '新規',
  'user-avatar': 'アバンタ',
  'user-name': '名前',
  'user-sex': '性別',
  'user-age': '年齢',
  'user-actions': '操作',
  'user-delete-tip-message': 'このアイテムを削除してもよろしいですか？',
  'user-ok': '確認',
  'user-cancle': 'キャンセル',
  'user-save': '保存',
  'tooltip-text': '未成年',
  '$vuetify.input.clear': 'クリア',
  'user-address': '住所',
  'user-postalcode': '郵便番号',
  'table-no-data-text': 'データがありません',
  '$vuetify.pagination.ariaLabel.root': 'ページネーション',
  '$vuetify.pagination.ariaLabel.previous': '前へ',
  '$vuetify.pagination.ariaLabel.next': '次へ',
  '$vuetify.pagination.ariaLabel.currentPage': '現在のページ番号',
  '$vuetify.pagination.ariaLabel.page': 'ページ',
};
