<template>
  <div>
    <teleport to="body">
      <div
        class="toast-wrapper"
        v-show="showTeleportedContent"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          :id="'toast-' + item.id"
        >
          <v-alert
            class="mt-1"
            :density="item.density"
            :closable="item.closable"
            :prominent="item.prominent"
            :title="item.title"
            :type="item.type"
            :text="item.text"
            :variant="item.variant"
          ></v-alert>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref } from 'vue';
import { nanoid } from 'nanoid';
export default {
  setup() {
    const showTeleportedContent = ref(true);
    const items = ref([]);
    return { showTeleportedContent, items };
  },
  methods: {
    info(text) {
      this.add('info', text);
    },
    success(text) {
      this.add('success', text);
    },
    warning(text) {
      this.add('warning', text);
    },
    error(text) {
      this.add('error', text);
    },
    add(type, text) {
      const item = {
        id: nanoid(),
        type: type,
        text: text,
        prominent: false,
        closable: false,
        density: 'compact',
        variant: 'elevated',
      };
      this.items.push(item);
      this.showTeleportedContent = true;

      setTimeout(() => {
        this.appear(item.id);
        setTimeout(() => {
          this.disappear(item.id);
          setTimeout(() => {
            this.items.shift();
            this.disappear(item.id);
            if (this.items.length === 0) this.showTeleportedContent = false;
          }, 300);
        }, 3000);
      }, 0);
    },

    appear(id) {
      document.getElementById('toast-' + id).classList.remove('noshow');
    },
    disappear(id) {
      document.getElementById('toast-' + id).classList.add('noshow');
      this.items
        .filter(item => {
          return item.id != id;
        })
        .forEach(item => {
          setTimeout(() => {
            this.appear(item.id);
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99999;
  width: 300px;
  display: flex;
  margin: 1rem;
  flex-direction: column-reverse;
  transition: height 0.3s;
  overflow: hidden;
}
:deep() {
  .mdi-close {
    color: white;
  }
}

.toast-wrapper div {
  width: 300px;
  margin-left: 0px;
}

.toast-wrapper div.noshow {
  transition: margin-left 0.3s;
  margin-left: -300px;
}
</style>
