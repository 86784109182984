import axios from '../axios';

export default {
  createUser(data) {
    return axios.post('/api/user/create', data);
  },
  readUser(data) {
    return axios.get('/api/user/read', { params: data });
  },
  updateUser(data) {
    return axios.put('/api/user/update', data);
  },
  deleteUser(data) {
    return axios.delete('/api/user/delete', data);
  },
};
