/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/styles';
import { md1 } from 'vuetify/blueprints';

// Composables
import { createVuetify } from 'vuetify';

import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { createI18n, useI18n } from 'vue-i18n';
import { en, zhHans, ja } from 'vuetify/locale';
import { fa } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';

import enMessages from '@/locales/en.js';
import zhHansMessages from '@/locales/zh.js';
import jaMessages from '@/locales/ja.js';

const messages = {
  zhHans: { ...zhHans, ...zhHansMessages },
  en: { ...en, ...enMessages },
  ja: { ...ja, ...jaMessages },
};

const i18n = new createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: 'ja',
  fallbackLocale: 'en',
  messages,
});

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    // aliases,
    sets: {
      fa,
      mdi,
    },
  },
  blueprint: md1,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        // colors should be handled carefully.
        colors: {
          // primary: "#1867C0",
          // secondary: "#5CBBF6",
        },
      },
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
});

export { i18n };
