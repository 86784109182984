import { rest } from 'msw';
import { delay } from './config';

export const route = [
  rest.get('/api/route', (req, res, ctx) => {
    return res(
      delay(),
      ctx.status(200),
      ctx.json([
        {
          name: 'label-home',
          icon: 'mdi-home',
          path: '/home',
        },
        {
          name: 'label-crud',
          icon: 'mdi-file-search',
          path: '/crud',
        },
        {
          name: 'label-helloworld',
          icon: 'mdi-information',
          path: '/helloworld',
        },
        {
          name: 'label-common',
          icon: 'mdi-hexagon-multiple',
          path: '/common',
          subs: [
            {
              name: 'label-dialog',
              icon: 'mdi-dock-top',
              path: '/common/dialogs',
            },
            {
              name: 'label-snackbar',
              icon: 'mdi-chat',
              path: '/common/snackbar',
            },
            {
              name: 'label-toast',
              icon: 'mdi-message',
              path: '/common/toast',
            },
          ],
        },
        {
          name: 'label-dev',
          icon: 'mdi-hexagon-multiple',
          path: '/dev',
          subs: [
            {
              name: 'label-icon',
              icon: 'mdi-seed',
              path: '/dev/icon',
            },
          ],
        },
      ])
    );
  }),
];
