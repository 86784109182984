import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useMockStore = defineStore(
  'mocksession',
  () => {
    const sessions = ref([]);

    const sessionsValue = computed(() => sessions.value);

    function setSessions(value) {
      sessions.value = value;
    }

    return {
      sessions,
      sessionsValue,
      setSessions,
    };
  },
  {
    persist: true,
  }
);
