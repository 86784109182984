import { inject, reactive, createVNode, render } from 'vue';
import { useTheme } from 'vuetify';
import { nanoid } from 'nanoid';
import ConfirmDialog from '@/components/common/dialogs/Confirm.vue';
import Snackbar from '@/components/common/snackbar/Snackbar.vue';
import Toast from '@/components/common/snackbar/Toast.vue';

const KEY = 'this_is_a_very_secure_and_global_unique_shortcut_key';

let toast;

function mount(component, props, app) {
  let el = null;

  function destroy() {
    if (el) render(null, el);
    el = null;
    vNode = null;
  }

  let vNode = createVNode(component, {
    ...props,
    destroy,
  });
  if (app && app._context) vNode.appContext = app._context;
  if (el) {
    render(vNode, el);
  } else if (typeof document !== 'undefined') {
    render(vNode, (el = document.createElement('div')));
  }
  return { vNode, destroy, el };
}

const plugin = {
  install(app, globalOptions) {
    const state = reactive({ promiseIds: new Map() });

    function mountDialog(options) {
      const promiseId = nanoid();
      mount(
        ConfirmDialog,
        {
          ...(globalOptions?.confirmDialog ?? {}),
          ...options,
          promiseId,
        },
        app
      );
      return new Promise((resolve, reject) => {
        state.promiseIds.set(promiseId, {
          resolve,
          reject,
        });
      });
    }

    function mountSnackbar(options) {
      mount(
        Snackbar,
        {
          ...(globalOptions?.snackbar ?? {}),
          ...options,
        },
        app
      );
    }

    function mountToast(options) {
      const wrapperEl = document.getElementsByClassName('toast-wrapper');
      // this should be globally unique
      if (wrapperEl.length === 0) {
        toast = mount(
          Toast,
          {
            ...(globalOptions?.toast ?? {}),
            ...options,
          },
          app
        ).vNode;
      }
    }

    app.provide(KEY, {
      mountDialog,
      mountSnackbar,
      mountToast,
      state,
    });

    app.config.globalProperties.$confirm = options => {
      return mountDialog(options);
    };
    app.config.globalProperties.$snackbar = options => {
      return mountSnackbar(options);
    };
    app.config.globalProperties.$successToast = (text, options) => {
      mountToast(options);
      toast.component.proxy.success(text);
    };
    app.config.globalProperties.$infoToast = (text, options) => {
      mountToast(options);
      toast.component.proxy.info(text);
    };
    app.config.globalProperties.$warningToast = (text, options) => {
      mountToast(options);
      toast.component.proxy.warning(text);
    };
    app.config.globalProperties.$errorToast = (text, options) => {
      mountToast(options);
      toast.component.proxy.error(text);
    };
  },
};

function useConfirm() {
  const shortcuts = inject(KEY);
  const theme = useTheme();

  function confirm(options) {
    if (!shortcuts) throw new Error('Missing shortcuts instance');

    return shortcuts.mountDialog({
      theme: theme.name.value,
      ...options,
    });
  }

  return confirm;
}

function useSnackbar() {
  const shortcuts = inject(KEY);
  const theme = useTheme();

  function snackbar(options) {
    if (!shortcuts) throw new Error('Missing shortcuts instance');

    return shortcuts.mountSnackbar({
      theme: theme.name.value,
      ...options,
    });
  }

  return snackbar;
}

export { plugin as default, useConfirm, useSnackbar };
