<template>
  <div class="tw-flex tw-font-bold">demo1</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  // Component options go here
});
</script>

<script setup lang="ts">
import { onMounted } from 'vue';
import { add } from '../test';
onMounted(() => {
  var sum: number = add(10, 15);
  console.log(sum);
});

// function addNumbers(a: number, b: number) {
//   return a + b;
// }
</script>
