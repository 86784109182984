// Utilities
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useTokenStore = defineStore(
  'token',
  () => {
    const token = ref();

    const tokenValue = computed(() => token.value);

    function setToken(value) {
      token.value = value;
    }

    return {
      token,
      tokenValue,
      setToken,
    };
  },
  {
    persist: true,
  }
);
