<template>
  <v-theme-provider :theme="theme">
    <v-dialog
      v-bind="finalDialogProps"
      v-model="isOpen"
      open-on-focus
    >
      <v-card v-bind="cardProps">
        <component
          :is="titleComponent"
          v-if="titleComponent"
          v-bind="titleComponentProps"
        />
        <v-card-title
          v-else
          v-bind="cardTitleProps"
        >
          {{ title }}
        </v-card-title>
        <v-card-text v-bind="cardTextProps">
          <component
            :is="contentComponent"
            v-if="contentComponent"
            v-bind="contentComponentProps"
          />
          <v-text-field
            v-else-if="confirmationKeyword"
            ref="textFieldInput"
            v-model="textField"
            v-bind="confirmationKeywordTextFieldProps"
            variant="underlined"
          />
          <template v-else>
            {{ content }}
          </template>
        </v-card-text>
        <v-cardActions v-bind="cardActionsProps">
          <v-spacer />
          <v-btn
            v-bind="cancellationButtonProps"
            @click="cancel"
            ref="cancelBtn"
          >
            {{ cancellationText }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="confirmationButtonDisabled"
            v-bind="confirmationButtonProps"
            @click="confirm"
          >
            {{ confirmationText }}
          </v-btn>
        </v-cardActions>
      </v-card>
    </v-dialog>
  </v-theme-provider>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue';

const props = defineProps({
  title: {
    required: false,
    default: 'Are you sure?',
  },
  titleComponent: {
    required: false,
  },
  titleComponentProps: {
    required: false,
  },
  content: {
    required: false,
    default: '',
  },
  contentComponent: {
    required: false,
  },
  contentComponentProps: {
    required: false,
  },
  confirmationKeyword: {
    required: false,
  },
  confirmationKeywordTextFieldProps: {
    required: false,
    default: () => ({}),
  },
  confirmationText: {
    required: false,
    default: 'Ok',
  },
  cancellationText: {
    required: false,
    default: 'Cancel',
  },
  dialogProps: {
    required: false,
    default: () => ({}),
  },
  cardProps: {
    required: false,
    default: () => ({}),
  },
  cardTitleProps: {
    required: false,
    default: () => ({}),
  },
  cardTextProps: {
    required: false,
    default: () => ({}),
  },
  cardActionsProps: {
    required: false,
    default: () => ({}),
  },
  cancellationButtonProps: {
    required: false,
    default: () => ({}),
  },
  confirmationButtonProps: {
    required: false,
    default: () => ({}),
  },
  theme: {
    required: false,
  },
  destroy: {
    required: true,
  },
  promiseId: {
    required: true,
  },
});

const dialog = inject('this_is_a_very_secure_and_global_unique_shortcut_key');
const isOpen = ref(true);
const textFieldInput = ref | (null > null);
const cancelBtn = ref(null);
const textField = ref('');

function confirm() {
  dialog?.state.promiseIds.get(props.promiseId)?.resolve?.(true);
  isOpen.value = false;
}

function cancel() {
  dialog?.state.promiseIds.get(props.promiseId)?.resolve?.(false);
  isOpen.value = false;
}

onMounted(() => {
  // textFieldInput.value?.focus();
  cancelBtn.value.$el.focus();
});

const confirmationButtonDisabled = computed(() => {
  if (!props.confirmationKeyword) return false;

  return props.confirmationKeyword !== textField.value;
});

const finalDialogProps = computed(() => {
  return {
    ...props.dialogProps,
    onAfterLeave() {
      props.dialogProps.onAfterLeave?.();
      dialog?.state.promiseIds.delete(props.promiseId);
      props.destroy();
    },
  };
});
</script>
