import { login } from './login';
import { logout } from './logout';
import { route } from './route';
import { bypass } from './bybass';
import { session } from './session';
import { dummy } from './dummy';
import { createUser } from './user/create';
import { readUser } from './user/read';
import { updateUser } from './user/update';
import { deleteUser } from './user/delete';

export const handlers = [
  ...login,
  ...logout,
  ...route,
  ...dummy,
  ...session,
  ...bypass,
  ...createUser,
  ...readUser,
  ...updateUser,
  ...deleteUser,
];
