import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { errorToast } from '@/utils/instance';
import { useTokenStore } from '@/store/token';
import { useHealthStore } from '@/store/heath';
import { SESSION_TIMEOUT_CHECK_URL } from '@/apis/session';

NProgress.configure({ showSpinner: false });

let service = axios.create({
  // headers: {
  //   "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  // },
  timeout: 10000,
});

service.interceptors.request.use(
  config => {
    if (SESSION_TIMEOUT_CHECK_URL != config.url) NProgress.start();
    console.log(config.url);
    const { tokenValue } = useTokenStore();
    config.headers.Authorization = 'Bearer ' + tokenValue;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    NProgress.done();
    const { setTime } = useHealthStore();
    setTime(new Date(Date.now()).toString());
    return response;
  },
  error => {
    NProgress.done();
    let msg = '';
    if (error.response) {
      msg = error.response?.status + ':' + error.response?.statusText;
    } else {
      msg = error;
    }
    errorToast(msg);
    throw new Error(msg);
  }
);

export default service;
